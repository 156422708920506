.footer-bg {
    background-color: #c2c8d1;
}

.single-footer-widget {
    padding: 100px 0 90px;
    text-align: start;
}

.widget-title {
    margin-bottom: 30px;
}

.widget-title h2 {
    line-height: 1;
    font-size: 20px;
    color: black!important;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 600;
}
.footer-link {
    font-weight: 400;
    display: block;
    line-height: 34px;
    color: black!important;
    font-size: 17px;

}

.footer-link:hover {
    color: white !important; 
}

.footer-copy-right {
    padding: 20px 0;
    background-color: gray;
}

.developer {
    color: white;
}


/* for mobile responsive */
@media only screen and (max-width: 426px){
    .single-footer-widget {
        padding: 45px 0 20px;
    }
}

@media only screen and (max-width: 321px){
    .footer-copy-right {
        padding: 20px 8px;
    }
    
}


.widget-content{
    font-size: 18PX;
}

@media only screen and (max-width: 500px){

.footer-link {
    font-size: 14px;
}
}