@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;1,400&family=Roboto+Slab:wght@300;400;500;600;700&display=swap');


.App {
  text-align: center;
  font-family: 'Lora', serif;
  font-family: 'Roboto Slab', serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gallery {
  width: 100%;
  max-width: 1200px; /* Set a maximum width for larger screens */
  height: auto; /* Automatically adjust height based on content */
 
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  justify-content: space-between; /* Space items evenly */
  margin: 0 auto; /* Center the gallery on the page */
  padding: 10px; /* Add some space around the items */
}

.gallery2 {
  width: calc(32% - 10px); /* Adjusted width and spacing */
  height: auto; /* Automatically adjust height based on content */
  margin-bottom: 10px; /* Add some space between items */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.gallery2 img {
  width: 100%;
  height: auto; /* Automatically adjust height based on width */
}

