.gallery-wrapper {
   background-color: lightgreen;
    padding: 120px 0;
}

.single-item-box img {
    border-radius: 12px;
    height: 332px;
    z-index: 1;
    margin-top: 30px;
    overflow: hidden;
    min-width: 100%;
}

.thumbnail p {
    font-size: 15px;
}

.single-item-box{
    margin-top: 20px;
}

.thumbnail{
    
    width: 350px;
    height: 350px;
    color: black;
    box-shadow: 2px 3px 5px -2px black;
}

.thumbnail h2 {
    font-size: 23px;
    padding-top: 03px;
    text-align: left;
    margin-left: 70px;
}
.thumbnail p  {
    text-align: left;
    margin-left: 70px;
    
}
.doctors{
    text-shadow: 2px 2px 4px white;
    font-size: 18px;
    color: black;
}